.view-order {

    .shadow{
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    }

    .row {
        margin-bottom: 10px;
    }

    .statusBar{
        display: inline-block;
        float: left;
        text-align: center;
        padding: 0;
        border: none;
        border-radius: 2px;
        height: 60px;
        width: auto;
        margin-right: 10px;
        h4{
            font-size: 1.2rem;
            border-radius: 5px 5px 0 0;
            background-color: #FFF;
            padding: 4px 10px;
            color: #222;
        }
        h3{
            font-size: 1.7rem;
            padding: 0px 15px;
            color: #555;
        }
    }

    .infoBox{
        display: block;
        padding: 15px;
        background-color: #EDEDED;
        font-size: 13px;
        color: #444;
    }

    .title{
        padding: 5px;
        background-color: #fff;
        span{
            font-size: 15px;
            padding: 5px;
            strong{
                font-size: 20px;
            }
        }
        h3{
            margin-bottom: 0px;
            padding-left: 10px;
        }
    }

    .table-listing{
        background-color: #FFF;
        margin-bottom: 20px;
        thead tr{
            background-color: $storeking;
        }
        tbody tr {
            td{
                border-bottom: 1px solid #DDD;
            }
        }
    }






    font-size: 1.2rem;
    .multirowtd {
        line-height: 3vh;
    }
    .invtab {
        width: 65%;
    }
    .list-group-item-header {
        background: rgba(0, 0, 0, 0.1);
        padding: 0.5rem;
    }
    .btn-info-light {
        background: #d9edf7 !important;
        border-color:rgb(221, 221, 221) !important;
    }
    .btn-success-light {
        background: #dff0d8 !important;
        border-color: rgb(221, 221, 221) !important;
    }

    .orderWell{
        height: auto;
        margin-bottom: 20px;
        padding: 10px;
        padding-bottom: 10px;
        border-bottom: 2px solid #BBB;
        h2 {
            padding: 2px;
            color: #333;
        }
    }

    .floatingMenu {
        position: fixed;
        top: 25%;
        right: 0%;
        z-index: 100;
        width: 150px;

        a {
            color: white;
        }

        li {
            background-color: #292664;
        }
        
        li:hover {
            background-color: #E56359;
        }
    }
}
