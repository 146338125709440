.segregation-scan {
    .segregation-box {
        width: 140px;
        height: 100px;
        background-color: grey;
        margin-left: 5px;
        border: 1px solid grey;
        color: white;
        font-weight: bold;
        padding-top: 1rem;
        margin-left: 1rem;
        box-shadow: .25rem .25rem .25rem grey;
        border-radius: 0.5rem;
        .counter {
            padding-top: 2rem;
            font-size: 3rem;
        }
        .header {
            height: 30px;
        }
        .footer {
            position: absolute;
            bottom: 0.3rem;
        }
        a {
            color: white;
        }
    }
    .segregation-box.red {
        background-color: red;
    }
    .segregation-box.blue {
        background-color: blue;
    }
    .segregation-box.green {
        background-color: green;
    }
    .segregation-box-equals {
        width: 1rem !important;
        text-align: center;
        padding-top: 5rem;
        font-size: 2rem;
        font-weight: bold;
    }
}

@media print {
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
        float: left;
    }
    .col-md-12.segregation-box {
        width: 100%;
    }
    .col-md-11.segregation-box {
        width: 91.66666666666666%;
    }
    .col-md-10.segregation-box {
        width: 83.33333333333334%;
    }
    .col-md-9.segregation-box {
        width: 75%;
    }
    .col-md-8.segregation-box {
        width: 66.66666666666666%;
    }
    .col-md-7.segregation-box {
        width: 58.333333333333336%;
    }
    .col-md-6.segregation-box {
        width: 50%;
    }
    .col-md-5.segregation-box {
        width: 41.66666666666667%;
    }
    .col-md-4.segregation-box {
        width: 33.33333333333333%;
    }
    .col-md-3.segregation-box {
        width: 25%;
    }
    .col-md-2.segregation-box {
        width: 16.666666666666664%;
    }
    .col-md-1.segregation-box {
        width: 9.2%;
    }
    .segregation-box {
        width: 120px;
        height: 120px;
        background-color: grey;
        border: 1px solid black;
        color: white;
        font-size: 1rem;
        font-weight: bold;
        padding-top: 1rem;
        margin-left: 1.3rem;
        .counter {
            padding-top: 3rem;
            font-size: 3rem;
        }
        .header {
            height: 30px;
        }
        .footer {
            position: absolute;
            bottom: 0.3rem;
            display: none;
        }
        a {
            color: white;
        }
    }
    .segregation-box.red {
        background-color: red;
    }
    .segregation-box.blue {
        background-color: blue;
    }
    .segregation-box.green {
        background-color: green;
    }
    .segregation-box-equals {
        width: 1rem !important;
        text-align: center;
        padding-top: 5rem;
        font-size: 2rem;
        font-weight: bold;
    }
    .margin-top-3 {
        margin-top: 3rem;
    }
}

.height-60{
	max-height: 60vh;
	overflow-y: auto;
	overflow-x: hidden;
}

.scan-success {
	background-color: rgb(49,206,49);
	width: 100%;
	height: 100vh;
}

.scan-error {
	background-color: rgb(255,59,59);
	width: 100%;
	height: 100vh;
}

.scan-warning {
	background-color: rgb(255,165,0);
	width: 100%;
	height: 100vh;
}

.scan-text{
	font-size: 6rem;
	color: white;
	font-weight: bold;
}

.normal-text{
	font-size: 4rem;
	font-weight: bold;
	color: black;
}