.forwardRoutes {
	.forwardList {
		list-style: none;
		display: flex;
        flex-direction: row;
        flex-wrap:wrap;
        justify-content: flex-start;
		.forwardListItem {
			background: #FFF;
            margin: 5px 10px;
            padding: 10px; 
            width: 140px;
            height: 120px;
            outline:none;
            position: relative;
            border-radius: 5px;
            
            .iconStyle {
            	position: absolute;
            	cursor: default;
            	margin-left: -25px;
            	margin-top: -25px;
            	color: white;
            	width: 30px;
            	height: 30px;
            	border-radius: 50%;
            	outline: none;
            	border: none;
            };
		};
		
		:first-child {
        	margin-left: 0px;
        }
	};
	.forwardListReached {
		border: 3px solid green;
		
		.iconStyle {
			background-color: green;
		};
	};
	.forwardListDefault {
		border: 3px solid red;
		
		.iconStyle {
			background-color: red;
		};
	};
};