.profile-view-password-icon-pos{
    position: absolute;
    z-index: 999;
    right: 20px;
    top: 28px;
}

.franchise-view-password-icon-pos{
    position: absolute;
    z-index: 999;
    right: 20px;
    top: 70px;
}

.emp-view-password-icon-pos {
    position: absolute;
    z-index: 999;
    right: 20px;
    top: 30px;
}

.force-login-view-password-icon-pos {
    position: absolute;
    z-index: 999;
    right: 20px;
    top: 6.3em;
}

.login-view-password-icon-pos {
    position: absolute;
    z-index: 999;
    right: 20px;
    top: 10px;
}


