.table-panel {
  height: 295px;
}
/* basic_activation_detail lie height*/

.basic_activation_detail {
 .table > tbody > tr > td {
 line-height: 24px;

}
}