.orderListInvoiceSlide {
	-webkit-box-shadow: 0px -2px 3px 0px rgba(0,0,0,0.43);
    -moz-box-shadow: 0px -2px 3px 0px rgba(0,0,0,0.43);
    box-shadow: 0px -2px 15px 0px rgba(0,0,0,0.50);
	background-color: #DDD;
	color: white;
	padding: 10px;
	.toprow{
		margin: 2rem 0.5rem;
	};

	.invoice-batch {
		height:	500px;
		overflow-y: auto;

		margin-top: 1rem;

		background-color: #EFEFEF;
		color: #333;
		
		tbody img {
			height: 100px;
			width: 100px;
		};
	};

	.font-xl {
	    font-size: 2.3rem;
	    color: black;
	    font-weight: 800;
	}

	.font-l {
	    font-size: 1.5rem;
	    color: #eee;
	    padding: 1px 8px;
	    background-color: #01579B;
	    margin: 2px;
	}

	.font-m {
	    font-size: 1.2rem;
	    color: #eee;
	    padding: 1px 5px;
	    background-color: #333;
	    margin-right: 5px;
	}

	.font-m2 {
	    font-size: 1.8rem;
	    color: #333;
	    font-weight: 600px;
	    padding: 1px 5px;
	    margin-right: 5px;
	    text-align: right;
	}
};

/* common style for column padding */

.pad-lr0{
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.pad-r0{
	padding-right: 0 !important;
}
.pad-l0{
	padding-left: 0 !important;
}

/* order list filter large text padding adjustment */

.order-list-filter{
	.form-control{
		padding: 6px 3px;
	}
	select.form-control {
		padding-left: 3px;
	}
}