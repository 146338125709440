.product-sk-display {
    padding: 0.5em;
    color: $primary;
	font-size: 0.80em;
    b {
		font-size: 1em;
        font-weight: 500;
        color: #000;
        margin-bottom: 0.1em;
        display: inline;
		width: 30% !important;
    }
}

.product-sk-display-name {
    font-size: 1em;
    color: $primary;
}

.product-panel{
	.panel{
		margin-bottom: 1.5% !important;
		.panel-body{
			padding: 1% !important;
		}
	}
}

.nopadding{
	padding-right: 1% !important;
	padding-left: 0 !important;
}

.markdown {
	p{
		font-size: 0.85em !important;
	}
}

.productBottompageslide{
	height: 75px !important;
	padding: 20px;
	background-color: $pageslide-bottom-bg;
	-webkit-box-shadow: 0px -2px 3px 0px rgba(0,0,0,0.43);
	-moz-box-shadow: 0px -2px 3px 0px rgba(0,0,0,0.43);
	box-shadow: 0px -2px 3px 0px rgba(0,0,0,0.43);
}


.inwardScanListBottomPageSlide{
	height: 120px !important;
	padding: 20px;
	background-color: $pageslide-bottom-bg;
	-webkit-box-shadow: 0px -2px 3px 0px rgba(0,0,0,0.43);
	-moz-box-shadow: 0px -2px 3px 0px rgba(0,0,0,0.43);
	box-shadow: 0px -2px 3px 0px rgba(0,0,0,0.43);
}

.verticalScroll {
	max-height: 300px;
	overflow-y: auto;
    overflow-x: hidden;
}

/*table.table-fixedheader {
    width: 100%;
}

table.table-fixedheader, table.table-fixedheader>thead, table.table-fixedheader>tbody, table.table-fixedheader>thead>tr, table.table-fixedheader>tbody>tr, table.table-fixedheader>thead>tr>th, table.table-fixedheader>tbody>td {
    display: block;
}

table.table-fixedheader>thead>tr:after, table.table-fixedheader>tbody>tr:after {
    content:' ';
    display: block;
    visibility: hidden;
    clear: both;
}

 table.table-fixedheader>tbody {
    overflow-y: scroll;
	float: left; 
    height: 270px;
}

 table.table-fixedheader>thead {
    overflow-y: scroll; 
	float: left; 
}*/
