.hrStyle{
	margin-bottom: 0 !important;
	margin-top: 0 !important;
}

.deal-sk-display {
    padding: 0.5em;
    color: $primary;
	font-size: 1.4rem;
    b {
		font-size: 1em;
        font-weight: normal;
        color: #000;
        margin-bottom: 0.1em;
        display: inline;
    }
}

.deal-sk-display-name {
    font-size: 0.85em;
    /*color: $primary;*/
}

.deal-display-name {
    font-size: 0.85em;
    color: $primary;
}
.deal-display {
    font-size: 1.7rem;
    color: $primary;
}
.deal-display-size {
    font-size: 1.7rem;
}
.listDealpageslide{
    height: 10% !important;
    background-color: #1f2b3a;
    padding: 10px;
    -webkit-box-shadow: 0px -2px 3px 0px rgba(0,0,0,0.43);
    -moz-box-shadow: 0px -2px 3px 0px rgba(0,0,0,0.43);
    box-shadow: 0px -2px 3px 0px rgba(0,0,0,0.43);
    color: #EEE;
}

/* Image zoom issue fixes */
.image-zoom-container.row > div:nth-child(3) .zoom-image{
    left: -38rem !important;
}

.image-zoom-container.row > div:nth-child(4) .zoom-image{
    left: -38rem !important;
}

.scroll-fix {overflow:auto; }


.row.img-container div:nth-child(4n) div { left: -54rem !important; }
.row.img-container div:nth-child(3n) div { left: -39rem !important; }