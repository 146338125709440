.shipment-handover-scan {
	.courierList {
		margin: 10px auto;
		width: 30vw;
		
		table {
			td {
				border-top: none;
				text-align: center;
			}
		}
	}
}
