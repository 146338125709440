.courierManage {
	.rateCard {
		.rateCardHeading {
			line-height: 30px;
		};
		.rateCardForm {
			padding: 20px;
			
			.valid {
				border: 1px solid green;
			};	
			ul {
				list-style-type: none;
				padding-left: 0;
			};
			li {
				margin-bottom: 20px;
				min-height: 20px;
				background-color: #f5f5f5;
				border: 1px solid #e3e3e3;
				border-radius: 3px;
				padding: 10px;
				box-shadow: inset 0 1px 1px;
				box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
				
				textarea {
					height: auto;
				}

				textarea[readonly], input[disabled] {
					color: black;
				}
				
				.rateTypeLabel {
					position: relative;
					
					h3 {
						position: absolute;
						margin-top: -25px;
					};
				};
			};
		};
	};
};