.shipmentMethod {
	.shipListTable {
		thead tr {
	        background-color: #263238;
	        font-weight: 500;
	        font-size: 1.3rem;
	        color: #fff;
	        height: 30px;
	        text-align:center;
	    };
	    tbody td {
	        padding: 0 5px;
	        text-align: center;
	        .form-group input {
	            width: 75%;
	        };
	    };
	    tbody tr{
	        font-size: 1.3rem;
	        vertical-align: middle;
	    };
	    tbody tr:hover {
	    	background-color: #f2f2f2;
	    };
	};
	.franchiseList {
		overflow:hidden;
		h3, li {
			text-align: center;
		}
		ul {
			float: left;
			width: 33%;
			list-style: none;
		}
		li {
			font-size: 18px;
			padding: 5px;
		}
		h3 {
			border-bottom: 1px solid black;
			padding: 15px;
		}
	};

	.apply-filter-shipment{
		margin-left: 10px;
	};


	}

