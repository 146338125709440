.manage-rack-bin {
    table tbody tr.active td {
        background-color:#D1EEEE;
        font-weight:bold;
    }
    .scroll {
        height:30vh;
        overflow-y:auto;
    }
}
