hr.customHr {
  margin-top: 10px;
  margin-bottom: 10px;
}

.st-sort-ascent:before {
  content: "\25B2";
}

.st-sort-descent:before {
  content: "\25BC";
}

.rm-border {
  border: none !important;
}

.rm-padding {
  padding: 0px !important;
}

.rm-padding-left {
  padding-left: 0px !important;
}

.padding-left-10 {
  padding-left: 10px;
}

.full-description {
  width: 100%;
  overflow: scroll;
}

.disabled-area {
  pointer-events: none;
  opacity: 0.4;
}

pageslide .footer-bar {
  position: absolute;
  bottom: 1em;
  z-index: 10;
  width: 100%;
}

.digital-log-container {
  height: 300px;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.skWidget-height {
  height: 300px;
  overflow: scroll;
}

.or-notation {
  margin-top: 20px;
  display: block;
  border: 1px solid;
  border-radius: 10px;
  padding: 3px;
  width: 27px;
  font-weight: bold;
}

/*FOR DISABLING NUMBER INPUT SCROLL ARROWS*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.md-autocomplete-suggestions li {
  text-overflow: initial;
  white-space: initial;
  height: auto;
  line-height: 25px;
  padding: 12px 10px;
}

.textOverFlow .dropdown-menu {
  text-overflow: initial;
  white-space: initial;
  height: auto;
  line-height: 25px;
  padding: 12px 10px;
  width: 24%;
}

.dangerBlock {
  color: red;
  background-color: wheat;
  font-size: 15px;
}

.map-view-order {
  .gm-style-iw {
    width: 800px;
    overflow: hidden;
    padding: 10px 15px;
  }

  .form-block {

    .col-md-2,
    .col-lg-2,
    .col-xs-2 {
      width: 14%;
      padding: 5px;

      .form-group {
        margin-bottom: 0px;

        .form-control {
          font-size: 11px;
        }
      }
    }
  }

  .overall-summary-block {
    position: absolute;
    right: 15px;
    z-index: 9;
    width: 350px;

    li {
      padding: 5px;
    }

    p {
      font-size: 12px;
    }
  }

  .tiers-block {
    position: absolute;
    top: 0px;
    z-index: 10;
    width: 100%;
    left: 20px;

    .col {
      width: 11%;
      padding: 1px;
      font-size: 12px;
    }
  }

  .top-list-block {
    position: absolute;
    width: 90%;
    bottom: 0px;
    padding: 5px 10px;

    .col {
      display: inline-block;
      float: none;
    }
  }

  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 100%;
    border-radius: 5px;
    background: white;
    overflow: hidden;
    height: 55px;
    text-align: left;
    cursor: pointer;

    .card-img-container {
      width: 55px;
      height: 55px;
      object-fit: contain;

      .sk-image {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .card-title {
      font-size: 12px;
      margin: 10% 0%;
    }
  }

  .carousel {
    .carousel-control {
      background: transparent;
      width: 5%;

      .glyphicon {
        color: black;
      }
    }

    .carousel-indicators {
      display: none;
    }
  }

  img {
    border-radius: 5px 5px 0 0;
  }

  .top-brand-block {
    position: absolute;
    left: 0px;
    width: 100px;
    bottom: 20%;
    z-index: 10;

    .panel-heading {
      padding: 13px;
      font-size: 12px;
    }

    .list-group {
      height: 150px;
      overflow: scroll;
    }
  }
}

.kvi-classification {
  .tab-content {
    background: white;
  }
}

.manage-product-warehouse-config {
  label {
    font-size: 11px;
  }
}

.calendar {
  .percent-val {
    font-size: 11px;
    float: right;
    padding-top: 3px;
  }

  .ttl-amount {
    font-size: 11px;
    float: right;
    padding-top: 3px;
  }

  .label-title {
    font-size: 0.8em;
  }

  .label-count {
    font-size: 1em;
  }

  .fc-event {
    font-size: 0.95em;
    font-weight: 800;
    padding: 5px 10px !important;
    background: white;
    color: black;
  }

  .fc-basic-view {
    .fc-body {
      .fc-row {
        min-height: 18em;
      }
    }
  }

  .fc-time {
    display: none;
  }
}

.c-overall-card {
  border: 1px solid #dede;
  padding: 10px;
  box-shadow: 0px 5px 5px #dedede;
  background: #fbfbfb;

  .c-order {
    font-weight: 600;
    font-size: 22px;
    padding: 5px;
  }

  .c-amount {
    font-size: 13px;
    padding: 10px;
  }

  .c-status {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
  }
}


.receipt-view-gird {
  .col-first {
    padding-right: 0px !important;

    li {
      border: none !important;
    }
  }

  .col-second {
    padding-left: 0px !important;

    li {
      border: none !important;
    }
  }
}

.high-z-index-modal {
  z-index: 1999 !important;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
  // background-color: DodgerBlue;
}

.flex-container>div {
  background-color: #f1f1f1;
  width: 100px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

#sso {
  div {
    &:first-child {
      width: auto !important;
    }
  }
}

.skoverlay {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 99999;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;

  /* Add a pointer on hover */
  .txt {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    color: white;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
}


.invoicePrintTemplateBlock * {
  font-family: Poppins
}

* {
  font-family: 'Poppins';
}

* h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins';
}


.c-overall-cardNew {
  border: 1px solid #dede;
  padding: 5px;
  box-shadow: 0px 5px 5px #dedede;
  background: #fbfbfb;

  .c-order {
    font-weight: 500;
    font-size: 16px;
    padding: 5px;
  }

  .c-amount {
    font-size: 13px;
    padding: 5px;
  }

  .c-status {
    font-size: 12px;
    font-weight: 500;
  }
}

.c-overall-cardExceededNew {
  border: 1px solid #dede;
  padding: 5px;
  box-shadow: 0px 5px 5px #dedede;
  background: #f78080;

  .c-order {
    font-weight: 500;
    font-size: 16px;
    padding: 5px;
  }

  .c-amount {
    font-size: 13px;
    padding: 5px;
  }

  .c-status {
    font-size: 12px;
    font-weight: 500;
  }
}

.fontBold-500 {
  font-weight: 500;
}

.fontBold-600 {
  font-weight: 600;
}

.p-20{
  padding:20px;
}