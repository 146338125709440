.segregationBatch {
	.topButtonClass {
		padding: 0px 0px 20px 0px;
	};
	.filter {
		margin-bottom: 20px;
	};
	.badgesStatus {
		p {
			background: #FFFAD6;
			padding: 20px;
			margin-right: 10px;
			font-weight: bold;
		};
	};
	.batchListTable {
		thead tr {
	        background-color: #263238;
	        font-weight: 500;
	        font-size: 1.3rem;
	        color: #fff;
	        height: 30px;
	        text-align:center;
	    };
	    tbody td {
	        padding: 0 5px;
	        text-align: center;
	        .form-group input {
	            width: 75%;
	        };
	    };
	    tbody tr{
	        font-size: 1.3rem;
	        vertical-align: middle;
	    };
	    tbody tr:hover {
	    	background-color: #f2f2f2;
	    };
	    tbody tr td:nth-child(2) {
	    	color: white;
	    	vertical-align: middle;
	    };
	};
	.blueBack {
		background-color: blue;
	};
	.greenBack {
		background-color: green;
	};
};