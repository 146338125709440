$default: #ffffff;
// $default: rgba(#292664, 0.1);
// $body-bg: #F0F3F4;
$body-bg: rgba(#292664, 0.04);;
$default-text: #434343;
$help-text: #949494;
$label-text: #ffffff;

$storeking: #292664;

$disabled: #999;
$disabled-bg: tint($disabled, 15%);
$border: #d6d6d6;
$border-light: tint($border, 15%);
$input-border: shade($border, 5%);
$input-background: #ffffff;
$input-background-disabled: rgba(0,0,0, 0.1);

$sidebar: #292664;
$sidebar-text: #EEE;
$sidebar-selected: #222;
// $sidebar-hover: #FFDD00;
$sidebar-hover: #E56539;
$dropdown-text: #7d7d7d;
$bootstrap-panel-text: #7d7d7d;
$bootstrap-panel-bg: #ffffff;
$mail-box: whitesmoke;
$auth-panel-background: #ffffff;
$progress-background: rgba(#000000, 0.07);
$progress-default: rgba(#000000, 0.15);

$pageslide-bottom-bg: #1f2b3a;

// $primary: #1E88E5 !default;
$primary: #428bca !default;
// $info: #01579B !default;
$info: #5bc0de !default;
// $success: #009e00 !default;
$success: #5cb85c !default;
// $warning: #FF8800 !default;
$warning: #f0ad4e !default;
// $danger: #cc0000 !default;
$danger: #d9534f !default;

$primary-light: tint($primary, 40%);
$info-light: tint($info, 30%);
$success-light: tint($success, 30%);
$warning-light: tint($warning, 30%);
$danger-light: tint($danger, 30%);

$primary-dark: shade($primary, 15%);
$info-dark: shade($info, 15%);
$success-dark: shade($success, 15%);
$warning-dark: shade($warning, 15%);
$danger-dark: shade($danger, 15%);

$primary-bg: tint($primary, 20%);
$info-bg: tint($info, 20%);
$success-bg: tint($success, 20%);
$warning-bg: tint($warning, 20%);
$danger-bg: tint($danger, 20%);

$tabs-bg :#545454;
$tabs-bg-selected :#2dacd1;
