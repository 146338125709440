.transferredImeiModal {
    margin-top: 10em;
}

.transferredImeiModal {
    .imeiDisplay{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .imeiDisplay {
        div {
            margin-top: 10px;            
        }
    }
    .imeiDisplay {
        div span{
            color: white !important;
        }
    }
}