.grnClass {
    a {
        cursor: pointer;
    }
    ul {}
    .poListClass {
        margin: 0;
        padding: 0px;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        .poListItem {
            background: #FFF;
            margin: 5px;
            padding: 10px;
            width: 140px;
            height: 120px;
            outline: none;
        }
        p {
            font-size: 12px;
            padding: 0px;
        }
        p:last-child {
            margin-bottom: 0px;
        }
    }
    .pageSlideClass {
        background: #e6e6e6;
        // background: #2760A9;
        color: white;
        padding: 15px;
        h4 {
            margin: 0;
            padding: 5px;
        }
        input:focus {
            border: 1px solid blue;
        }
    }
    ;
    .grnTablePanel {
        background-color: #FFF;
        box-shadow: 0px 2px 10px grey;
        border-radius: 5px;
        padding: 20px;
        margin-bottom: 20px;
    }
    ;
    .grnTableClass {
        thead.gtc tr {
            background-color: #263238;
            font-weight: 500;
            font-size: 12px;
            color: #fff;
            height: 30px;
        }
        tbody.gtc td {
            padding: 0 5px;
            .form-group input {
                width: 75%;
            }
        }
        tbody.gtc tr {
            font-size: 10px;
            vertical-align: middle;
        }
        tbody.gtc img {
            width: 100px;
            height: 100px;
        }
        p {
            font-size: 10px;
            margin-bottom: 2px;
        }
    }
    .tableEdit {
        background: lightskyblue;
    }
    .tableProcessed {
        background: lightgreen;
    }
    .form-control[disabled] {
        color: #434343;
    }
    .grn-select {
        height: 33px;
    }
}

.pageslideClass {
    background: #e6e6e6;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    // background: #2760A9;
    color: black;
    padding: 15px;
    h4 {
        margin: 0;
        padding: 5px;
    }
    input:focus {
        border: 1px solid blue;
    }
}

#editModeColor {
    width: 100px;
    height: 50px;
    padding: 0 20px;
    background: lightblue;
    border: 1px solid black;
}

#highlightModeColor {
    margin-top: 5px;
    width: 100px;
    height: 50px;
    padding: 0 20px;
    background: lightgreen;
    border: 1px solid black;
}

.grn-view {
    .warehouse-select {
        width: 12rem;
        float: right;
        font-size: .9rem;
        height: 2.7rem;
        margin-top: 1rem !important;
        padding-left: 1rem;
    }
}
.danger-imei{
    background-color: red;
}
