.order-batch-list{
	.createBatchForm {
		border-radius: 2px;
		background-color: #FFF;
		box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
		margin-bottom: 20px;

		.createBatchHeading {
			padding: 10px 30px;
			color: #434343;
			margin-bottom: 20px;
			p {
				font-size: 1.5rem;
			}
		};
		.createBatchData {
			
		};
		.createBatchRemarks {
			margin-left: 20px;	
		};
	};
	.summary-heading{
		display: inline-block;
		box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
		padding: 1rem;
		font-size: 15px;
		padding: 1rem;
		margin: 0.5rem; 
		.number{
			font-size: 3rem;
			color: #333;
			padding: 1rem;
			margin: 0.2rem;
		};
		.text{
			text-align: center;
			color: #888;
			margin: 0.2rem;
		};
		&.pending{
			background: #FFF8E1;
		};
		&.partial{
			background: #DCEDC8;
		};
	}
	.batch-table-listing{
		font-size: 13px;
		td, th {
			text-align: center;
		};
		thead{
			tr{
				font-size: 12px;
				color: #EEE;
				background-color: #0D47A1;
			};
		};
		tbody tr td{
			padding: 5px;
		};
	};
};
.batchInvoiceSlide {
	top: 8% !important;
	-webkit-box-shadow: 0px -2px 3px 0px rgba(0,0,0,0.43);
    -moz-box-shadow: 0px -2px 3px 0px rgba(0,0,0,0.43);
    box-shadow: 0px -2px 15px 0px rgba(0,0,0,0.50);
	background-color: darkgray;
	color: white;
	padding: 10px;
	.list-group{
		color: #333;
		.scanned{
			position: absolute;
			right: 0;
			padding-right: 10px;
			font-size: 2rem;
			color: #F57F17;
		};
	};
	.footer{
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 5vh;
		padding: 5px;
		background-color: #01579B;
	};
	.invoice-batch {
		height:	500px;
		overflow-y: auto;
		background-color: #EEE;
		color: #333;
		tbody img {
			height: 100px;
			width: 100px;
		};
	};

	.font-xl {
	    font-size: 2.3rem;
	    color: black;
	    font-weight: 800;
	}

	.font-l {
	    font-size: 1.5rem;
	    color: #eee;
	    padding: 1px 8px;
	    background-color: #01579B;
	    margin: 2px;
	}

	.font-m {
	    font-size: 1.2rem;
	    color: #eee;
	    padding: 1px 5px;
	    background-color: #333;
	    margin-right: 5px;
	}

	.font-m2 {
	    font-size: 1.8rem;
	    color: #333;
	    font-weight: 600px;
	    padding: 1px 5px;
	    margin-right: 5px;
	    text-align: right;
	}
};
.doneClass, .pendingClass {
	font-weight: bold;
}

.doneClass {
	color: #449d44;
}

.pendingClass {
	color: #c9302c;
}