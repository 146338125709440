.pending-sat-table{
	.table > tbody > tr > th {
	    border: none;
	    vertical-align: middle;
	    font-weight: bold;
	    font-size: 13px;


		input[type="radio"], input[type="checkbox"]{
		  margin:0;
		}
		i.fa{    
		    font-size: 24px;
		    color: #000;
		}
	}
}