.view-brand {
    .img-box {
        position:relative;
        padding: 1rem;
        width : 14rem;
        height : 14rem;
        float:left;
        margin-right:1rem;
        margin-bottom:2rem;
        img {
            width : 12rem;
            height : 12rem;
        };
        i {
            position:absolute;
            right: 0;
            top:-0.05rem;
        }
    }
    .img-360 {
        height:170px;
        
    }
}