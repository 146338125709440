.orderDash {
    .dash-box {
        display: block;
        height: 50px;
        width: 100%;
        background: #FFFFFF;
        margin-bottom: 2rem;
        i {
            height: 60px;
            width: 60px;
            position: absolute;
            top: 5;
            margin-left: 10px;
            border: 3px solid #EEE;
            background-color: #FFF;
            text-align: center;
            padding-top: 10px;
            border-radius: 30px;
            z-index: 100;
        }
        ;
        .dash-box-title,
        .count {
            z-index: 90;
            position: absolute;
            top: 5;
            width: 22vw;
            margin: 9px 10px 0 40px;
            color: #0187D1;
            text-align: left;
            font-size: 2rem;
            padding: 10px;
            padding-left: 40px;
        }
        ;
        .count {
            text-align: right;
            font-size: 1.5rem;
            color: #013755;
        }
        ;
        :hover {
            background: rgba(131, 203, 243, 0.27);
            cursor: pointer;
        }
        ;
    }
    ;
    .dash-panel {
        height: 9rem;
        padding: 2rem;
    }
    .dash-panel-fluid {
        padding-top: 2rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-bottom: 2rem;
    }
    .info-border {
        border-bottom: 3px solid $info;
    }
    .primary-border {
        border-bottom: 3px solid $primary;
    }
    .danger-border {
        border-bottom: 3px solid $danger;
    }
    .success-border {
        border-bottom: 3px solid $success;
    }
    .warning-border {
        border-bottom: 3px solid $warning;
    }
    .oc-label {
        font-size: 1.2rem;
    }
    .banner-icon {
        position: absolute;
        top: 2rem;
        left: 2rem;
        font-size: 4rem;
    }
}

.digitalOrderDash {
    .dash-panel {
        height: 9rem;
        padding: 2rem;
    }
    .dash-panel-fluid {
        padding-top: 2rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-bottom: 2rem;
    }
    .info-border {
        border-bottom: 3px solid $info;
    }
    .primary-border {
        border-bottom: 3px solid $primary;
    }
    .danger-border {
        border-bottom: 3px solid $danger;
    }
    .success-border {
        border-bottom: 3px solid $success;
    }
    .warning-border {
        border-bottom: 3px solid $warning;
    }
    .oc-label {
        font-size: 1.2rem;
    }
    .banner-icon {
        position: absolute;
        top: 2rem;
        left: 2rem;
        font-size: 3rem;
        width: auto;
    }
    .dp-details {
        max-height: 28vh;
        min-height: 27vh;
        overflow-y: scroll;
    }
    .top-rfs {
        font-size: 1.2rem;
        max-height: 28vh;
        min-height: 27vh;
    }
}
