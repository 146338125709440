.packageDetailsModal {
	.packageDetailsTable {
		thead tr {
	        background-color: #263238;
	        font-weight: 500;
	        font-size: 1.3rem;
	        color: #fff;
	        height: 30px;
	        text-align:center;
	    };
	    tbody td {
	        padding: 0 5px;
	        text-align: center;
	        .form-group input {
	            width: 75%;
	        };
	    };
	    tbody tr{
	        font-size: 1.3rem;
	        vertical-align: middle;
	    };
	    tbody tr:hover {
	    	background-color: #f2f2f2;
	    };
	};	
};