.shipmentMethodManage {
	.arrow {
	    width: 80px;
	    padding-top: 30px;
	}

	.line {
	    margin-top:14px;
	    width:50px;
	    background:black;
	    height:10px;
	    float:left;
	}
	.point {    
	    width: 0;
	    height: 0; 
	    border-top: 20px solid transparent;
	    border-bottom: 20px solid transparent;
	    border-left: 30px solid black;
	    float:right;
	}
}