.commissions-definition {
    .bold-label {
        font-weight: bold !important;
    }
}

.commission-container{
	width: 100%;
	padding-left: 20px;
	margin-top: 5em;
	height:90%;
	overflow-y: scroll;
}

.commission-fixed-bar{
	position: absolute;
    top: 65px;
    width: 100%;
    z-index: 100
}

.edit-commission{
	.editComm-remove{
		text-decoration: underline !important
	}
	.editComm-collapse{
		padding-left: 3em;
	}
	.editComm-type-name{
		font-size: 11px;
		color: gray;
	}
}