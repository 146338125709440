.content-top {
  padding-top: 5px;
  padding-bottom: 15px;
}

h1.al-title {
  //font-weight: $font-bold;
  color: $default-text;
  float: left;
  width: auto;
  margin: 0;
  padding: 0;
  font-size: 24px;
  text-transform: uppercase;
  opacity: 0.9;
}

.al-breadcrumb {
  background: none;
  color: $default-text;
  padding: 0;
  margin: 0;
  float: right;
  padding-top: 11px;
  li {
    font-size: 15px;
    font-weight: $font-normal;
  }
}

.al-look {
  float: right;
  margin-right: 10px;
  padding-top: 10px;
  > a {
    font-size: 10px;
  }
}