
.modal-content {
  border-radius: 2px;
  border: none;
  color: $dropdown-text;
  .modal-header {
    color: $label-text;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
}

.modal-buttons {
  margin-top: -12px;
  margin-right: -22px;
  button {
    float: left;
    margin-right: 24px;
    margin-top: 12px;
  }
  &.same-width {
    button {
      width: 160px;
      text-align: center;
    }
  }
}

$modal-input-border: #e7e7e7;

.modal {
  text-align: center;
  .modal-dialog {
    display: inline-block;
    text-align: left;
    margin: 0 auto;
    .form-control{
      color: $github-color;
      background-color: transparent;
      border: 1px solid $modal-input-border;
      @include placeholderStyle($dropdown-text, 0.9);
    }
    .modal-body p, .modal-body div, .modal-body span{
      color: $dropdown-text;
    }
  }
  .modal-header {
    border: none;
    .close{
      margin-top: -5px;
    }
  }
  .modal-footer {
    border: none;
    padding-top: 0;
  }
  .modal-icon{
    margin-right: 3px;
  }
  .sn-link-close {
    color: #949494;
    font-size: 30px;
    &:hover {
      color: $danger;
    }
  }
}

@media screen and (min-width: $resM) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-content .modal-header .modal-title{
  color: $default-text;
}

.message-modal {
  .modal-header {
    text-align: center;
    i {
      font-size: 32px;
    }
  }
  .modal-title {
    text-align: center;
    font-size: 18px;
    margin-bottom: 12px;
    margin-top: 18px;
  }
  .modal-body {
    text-align: center;
    padding: 0;
    font-size: 14px;
  }
  .modal-footer {
    margin: 24px 0 12px;
    text-align: center;
  }
}

@mixin message-modal-color($color) {
  .modal-header {
    color: $label-text;
    background: $color;
  }
}

.success-modal {
  @include message-modal-color($success);
}
.info-modal {
  @include message-modal-color($info);
}
.warning-modal {
  @include message-modal-color($warning);
}
.danger-modal {
  @include message-modal-color($danger);
}