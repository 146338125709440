    .box-segregation {
        ul.list-group.table-ul {
            height: 7rem;
            overflow-y: scroll;
            margin-top: 1rem;
            li.list-group-item {
                padding: 0;
                line-height: 20px
            }
        }
    }
    
    .boxPageslide {
        top: 7% !important;
        height: 100% !important;
        background-color: #ddd;
        padding: 10px;
        -webkit-box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.43);
        -moz-box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.43);
        box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.50);
    }
    
    .longPageslide {
        height: 100% !important;
        background-color: #ddd;
        padding: 10px;
        -webkit-box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.43);
        -moz-box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.43);
        box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.50);
    }
    
    .glyphicon.spinning {
        animation: spin 1s infinite linear;
        -webkit-animation: spin2 1s infinite linear;
    }

    .boxingModal .modal-dialog{
        position: fixed;
        top: 22%;
        margin-left: -10%;
    }

    .long-table .listRight-dropdown {
        left: auto !important;
        right: 0 !important;
    }
    
    @keyframes spin {
        from {
            transform: scale(1) rotate(0deg);
        }
        to {
            transform: scale(1) rotate(360deg);
        }
    }
    
    @-webkit-keyframes spin2 {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
        }
    }
