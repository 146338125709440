.lending-container{

    table {
        tr {
            td {
                line-height: 20px;
                padding: 8px;
            }
        }        
    }
}

.lendingTypeaheadBlock{
    .dropdown-menu{
        max-height: 150px;
        overflow-y: scroll;
    }
}