.seller-manager-profile {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.seller-linked-franchise {
    md-autocomplete {
        height: 35px;
        md-autocomplete-wrap {
            background: #fff;
            height: 35px;
            box-shadow: none;
            border-radius: 5px;
            border: 1px solid rgb(204, 204, 204);
        }
        input:not(.md-input) {
            height: 35px;
            padding: 0 0 0 10px
        }
    }
}

[window-class="seller-edit-modal"] .modal-lg{

    width:1000px;
}