.advancedFilter {
    height: 100% !important;
    background-color: #FFF;
    -webkit-box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.43);
    -moz-box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.43);
    box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.50);
    padding: 0rem;

	.panel{
		height: 100%;
		border-radius: 0px;
		margin-bottom: 0px; 
		.panel-heading{
			height:6%;
			border-radius: 0px;
			text-align: center;
		}
		.panel-body{
			height: 85%;
			border-radius: 0px;
			overflow-y:scroll; 
		}
		.panel-footer{
			height: 8%;
			border-radius: 0px;
		}
	}

    .well{
    	background-color: #EEE;
    	border: none;
    	label{
    		color: #263547;
    	}
    	input.form-control{
    		border: none;
    		border-radius: 0px;
    	}
    	select.form-control{
    		border: none;
    		option {
    			border-radius: 0;
    			-webkit-appearance: none;
    		}
    	}
    }
}