.trayScan {
	a:hover {
		cursor: pointer
	};
	table {
		tbody tr:hover {
	    	background-color: #f2f2f2;
	    };
	    td,th {
	    	text-align: left;
	    }
	};
	.green {
		color: green;
	};
	.blue {
		color: #3B5998;
	};
	.red {
		color: firebrick;
	};
};

.trayHeader{
	background-color: #E7E7E7 !important;
}