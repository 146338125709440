.orderListing {

    .franchiseInfo {
        display: block;
        padding: 4px 1px 1px 1px;
        p {
            line-height: 5px;
        }
        .franchiseName {
            font-weight: bold;
            font-size: 12px;
        }
        .franchiseId {
            border-radius: 2px;
            padding: 1px 4px;
            margin-right: 2px;
        }
        .franchiseAddr {
            font-size: 12px;
        }
    }


    .order-table-listing {
        font-size: 13px;
        td,
        th {
            text-align: center;
        }
        thead {
            tr {
                font-size: 12px;
                color: #EEE;
                background-color: #0D47A1;
            }
            ;
        }
        ;
        tbody tr td {
            padding: 5px;
        }
        ;
        .order-subtable {
            font-size: 12px;
            thead {
                padding: 0px;
                tr {
                    font-size: 11px;
                    color: #333;
                    background-color: #90CAF9;
                    th {
                        padding: 0px;
                        text-align: center;
                    }
                }
                ;
            }
            ;
            tbody {
                tr {
                    border-bottom: 1px solid #555;
                    td {
                        padding: 0px;
                        text-align: center;
                    }
                    ;
                }
                ;
            }
            ;
        }
        ;
    }
    ;
}