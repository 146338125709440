.invoicePackScan {
    .col-md-3, .col-md-2 {
        padding-left: 0px;
    };
    .invoiceState {
        ul {
            list-style: none;
            overflow: hidden;
            padding: 0;
            margin-top: 20px;
        };
        li {
            height: 90px;
            width: 90px;
            // border: 1px solid black;
            color: white;
            float: left;
            text-align: center;
            margin-right: 10px;
            margin-bottom: 20px;
            padding-top: 15px;
            font-size: 10px;
            border-radius: 5px;
            box-shadow: 2px 2px 2px grey;
            p:first-child {
                font-size: 1.5rem;
            };
            p:nth-child(2) {
                font-size: 3.5rem;
            };
        };
        li:last-child {
            margin-right: 0px;
        };
        li:nth-child(1) {
            background-color: #FF0000;
        };
        li:nth-child(2) {
            background-color: #EE82EE;
        };
        li:nth-child(3) {
            background-color: #FFA500;
        };
        li:nth-child(4) {
            background-color: #0000FF;
        };
        li:nth-child(5) {
            background-color: #800080;
        };
        li:nth-child(6) {
            background-color: #008000;
        };
        li:nth-child(7) {
            background-color: #FE9000;
        };
        li:nth-child(8) {
            width: 20px;
            color: black;
            border: none;
            padding-top: 25px;
            box-shadow: none;
        };
        li:nth-child(9) {
            background-color: #808080;
        };
    };
    .packClass {
        .packCounter {
            text-align: center;
            h1 {
                font-size: 5rem;
            }
        };
        table tbody td {
            text-align: left;
        };
        button {
            margin-top: 20px;
        };
    };
    .invoiceBox {
        color: white;
        height: 100px;
        width: 170px;
        border: 1px solid black;
        background-color: #008000;
        padding: 10px 10px;
        margin-right:0.5em;
    };
    .notfound {
        background-color: #FF0000;
    };
    .cancelled {
        background-color: #EE82EE;
    };
    .packed {
        background-color: #FFA500;
    };
    .shipped {
        background-color: #0000FF;
    };
    .unpaid {
        background-color: #800080;
    };
    .valid {
        background-color: #008000;
    };
    .wrongWarehouse{
        background-color: #FE9000;
    }
    .total {
        background-color: #808080;
    };
    .invoiceList {
        ul {
            padding-left: 0px;
            list-style: none;
            display: flex;
            flex-direction: row;
            flex-wrap:wrap;
            justify-content: flex-start;
        };
        
        li {
            margin-bottom: 10px;
            border: none;
            border-radius: 2px;
            box-shadow: 2px 2px 2px grey;
        };
    };
};
