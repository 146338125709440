.manage-coupon-template {
    .panel.panel-default {
        padding:2rem;
    }
    .table.table-condensed {
        input, button {
            margin-top:.7rem;
            margin-bottom:.7rem;
        }
        text-align: center;
    }
    .ng-invalid{
        border: 1px solid #003366;
    }
    .coupon-or{
        border: 2px solid #847f7f;
        border-radius: 14px;
        padding: 5px;
        color: #847f7f;
    }
}